<app-cw-new-card [showHeader]="true" [showBody]="true">
  <div header>
    <app-cw-card-header
      [title]="templateTitle | translation: templateContent.TEMPLATE_CONTENT: lang"
      [subtitle]="templateSubTitle | translation: templateContent.TEMPLATE_CONTENT: lang"
    />
  </div>

  <div body class="my-4 event-body">
    @if (cart.length <= 0) {
      <div class="no-data">{{ 'appointments.step4.cart.emptyCartWarning' | translate }}</div>
    }

    @if (cart) {
      <div>
        @for (cartItem of cart; track cartItem; let i = $index; let last = $last) {
        <div class="row cw-cart-row">
          <div class="col-md-12">
            <div class="d-flex align-items-center padding-left-15px">
              <div class="service">
                <ngx-clamp row="3">
                  <div class="name" #content>
                    <span class="cart text-break">
                      {{ cartItem.referenceEvent | translation: 'title' : lang }}
                    </span>
                  </div>
                </ngx-clamp>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="appointment mt-1 padding-left-15px" [ngClass]="{ 'appointment-padding': cart.length != 1 }">
              @if (((cartItem.startTime | dateutc: dateFormat) === (cartItem.endTime | dateutc: dateFormat))) {
                @if (cartItem?.childSlots?.length) {
                  @for (slot of cartItem?.childSlots; track slot.id) {
                    <div class="cbw-summary-date-time">
                      {{ slot.start | dateutc: datePipeWithComma }} - {{ slot.end | dateutc: timeFormat }}
                    </div>
                  }
                }
                @if (cartItem?.childSlots?.length === 0) {
                  <span class="cbw-summary-date-time">
                    {{ cartItem.startTime | dateutc: datePipeWithComma }} - {{ cartItem.endTime | dateutc: timeFormat }}
                  </span>
                }
              }
              @if (((cartItem.startTime | dateutc: dateFormat) !== (cartItem.endTime | dateutc: dateFormat))) {
                <span class="cbw-summary-date-time">
                  {{ cartItem.startTime | dateutc: dateTimeFormat }} - {{ cartItem.endTime | dateutc: dateTimeFormat }}
                </span>
              }
              @if (showWidgetSlotLocation) {
                <div class="cbw-summary-date-time d-flex mt-1">
                  @if (cartItem?.location) {
                    <img class="location-icon" [src]="deployUrl + '/assets/images/location.svg'" />{{ cartItem.location }}
                  } @else {
                    @if (
                      [1, 12].includes(eventState?.meeting_type_id) &&
                      eventState?.place?.trim() !== ',' &&
                      (eventState?.place | isEmptyString)
                    ) {
                      <img class="location-icon" [src]="deployUrl + '/assets/images/location.svg'" />{{ eventState?.place }}
                    } @else if (![1, 12].includes(eventState?.meeting_type_id) && eventState?.meeting_type_id && eventState?.meeting_type) {
                      <img class="location-icon" [src]="deployUrl + '/assets/images/location.svg'" />{{ eventState.meeting_type | translation: 'name' : lang }}
                    }
                  }
                </div>
              }
            </div>
            <div class="padding-left-15px mt-1 d-none d-md-block">
              <a class="remove-link" href="javascript:void(0)" (click)="removeFromCart( cartItem.slotId )">
                {{ 'buttons.remove' | translate }}
              </a>
            </div>
          </div>

          <div class="col-md-4">
            @if (eventState?.is_multi_day !== 1) {
              @if (partner.hide_mail_resource !== 1) {
                <div class="worker-name padding-left-15px">
                  @if ((cartItem.duration | replaceComma | eventToHour) > 0) {
                  <span>
                    {{ cartItem.duration | replaceComma | eventToHour }}{{ 'common.durationHour' | translate }}
                  </span>
                  }
                  @if ((cartItem.duration | replaceComma | eventToMinute) > 0) {
                    <span>
                      {{ cartItem.duration | replaceComma | eventToMinute }}{{ 'common.durationMinute' | translate }}
                    </span>
                  }
                </div>
              }
            }
          </div>

          <div class="col-md-2">
            <div class="no-wrap price text-md-end padding-left-15px">
              @if (cartItem.currentEvent?.price > 0) {
              <span>
                {{ cartItem.currentEvent?.price | calioCurrency: partner.currency }}
              </span>
              }
              @if (cartItem.currentEvent?.price <= 0) {
                <span>
                {{ cartItem.currentEvent?.price | price | translate | titlecase }}
                </span>
              }

              @if (cartItem?.currentEvent?.tax) {
                <div class="vat">
                  {{ 'summary_page_translations.tax' | translate: { 'rate': cartItem?.currentEvent?.taxRate } }}
                </div>
              }
            </div>
          </div>

          <div class="col-md-12 d-block d-md-none">
            <div class="padding-left-15px mt-1">
              <a class="remove-link" href="javascript:void(0)" (click)="removeFromCart(cartItem.slotId)">
                {{ 'buttons.remove' | translate }}
              </a>
            </div>
          </div>

          @if ((finalCouponDiscount > 0 || finalTotal !== 0 && cart && cart.length > 0) ||
            (cart && cart.length > 1 && !last)) {
              <hr class="card-divider w-100">
          }
        </div>
        }

        @if (finalCouponDiscount > 0 || finalTotal !== 0 && cart && cart.length > 0) {
        <div class="row cw-cart-row">
          <div class="col-lg-6 col-md-6"></div>
          <div class="col-lg-6 col-md-6">
            @if (additionalGuests?.length > 0) {
            <div class="d-flex justify-content-start justify-content-md-end">
              <div class="row total-row cw-cart-row padding-left-15px">
                <div class="col-lg-7 col-md-7 normal-field-label padding-left-15px">
                  @if (additionalGuests.length === 1) {
                  <div>{{ 'common.additionalGuestCartLabel' | translate : { number: additionalGuests.length } }}</div>
                  }
                  @if (additionalGuests.length > 1) {
                  <div>{{ 'common.additionalGuestsCartLabel' | translate : { number: additionalGuests.length } }}</div>
                  }
                </div>
                <div class="col-lg-5 col-md-5 text-md-end normal-field-label padding-left-15px">
                  <span class="">
                    @if (totalCartPrice > 0) {
                      @if (additionalGuests?.length > 0) {
                        {{ ((additionalGuests.length) * totalCartPrice) | calioCurrency: partner.currency }}
                      }
                      @if (additionalGuests?.length === 0) {
                        {{ totalCartPrice | calioCurrency: partner.currency }}
                      }
                    }
                    @if (totalCartPrice === 0) {
                      {{ 'free' | translate }}
                    }
                    @if (totalCartPrice === -1) {
                    &nbsp;
                    }
                  </span>
                </div>
              </div>
            </div>
            }

            <div class="d-flex justify-content-start justify-content-md-end">
              <div class="row total-row cw-cart-row padding-left-15px">
                <div class="col-lg-7 col-md-7 bold-field-label padding-left-15px">
                  <div>{{ 'summary_page_translations.subtotal' | translate }}</div>
                </div>
                <div class="col-lg-5 col-md-5 text-md-end bold-field-label padding-left-15px">
                  <span class="">
                    @if (totalCartPrice > 0) {
                      @if (additionalGuests?.length > 0) {
                        {{ ((additionalGuests.length + 1) * totalCartPrice) | calioCurrency: partner.currency }}
                      }
                      @if (additionalGuests?.length === 0) {
                      {{ totalCartPrice | calioCurrency: partner.currency }}
                      }
                    }
                    @if (totalCartPrice === 0) {
                    {{ 'free' | translate }}
                    }
                    @if (totalCartPrice === -1) {
                    &nbsp;
                    }
                  </span>
                </div>
              </div>
            </div>

            @if (coupon) {
            <div class="d-flex justify-content-start justify-content-md-end mt-2">
              <div class="row total-row cw-cart-row padding-left-15px">
                <div class="col-lg-7 col-md-7 normal-field-label padding-left-15px">
                  {{ 'coupon_credit_card_info.coupon' | translate }} ({{ coupon.code }})
                </div>
                <div class="col-lg-5 col-md-5 text-md-end normal-field-label padding-left-15px">
                  <span class="">
                    - {{ finalCouponDiscount | calioCurrency: partner.currency }}
                  </span>
                </div>
              </div>
            </div>
            }
            <div class="d-flex justify-content-start justify-content-md-end">
              <div class="row total-row cw-cart-row padding-left-15px">
                <hr class="card-divider w-100">
                <div class="col-lg-7 col-md-7 bold-field-label padding-left-15px">
                  {{ 'appointments.step4.total' | translate }}
                </div>
                <div class="col-lg-5 col-md-5 text-md-end red-bold-field-label padding-left-15px">
                  <span class="">
                    {{ finalTotal | calioCurrency: partner.currency }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        }
      </div>
    }
  </div>
</app-cw-new-card>
