import { NgClass } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, numberAttribute, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule, UntypedFormControl, ValidationErrors, Validator } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { WIDGET_CONSTANTS } from '../../../../constants/widget-constants';

declare var intlTelInput: any;

@Component({
  selector: 'app-cw-intl-tel-input',
  templateUrl: './cw-intl-tel-input.component.html',
  styleUrls: ['./cw-intl-tel-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CwIntlTelInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CwIntlTelInputComponent),
      multi: true
    }
  ],
  standalone: true,
  imports: [FormsModule, NgClass, ReactiveFormsModule, FontAwesomeModule, TranslateModule]
})
export class CwIntlTelInputComponent implements OnInit, ControlValueAccessor, Validator, AfterViewInit {

  @Input() defaultCountry: string;
  @Input() cssClass: string;
  @Input() E164PhoneNumber: string;
  @Input() label: string;
  @Input() labelCssClass: string;
  @Input() name = 'intl-tel-input-name';
  @Input() onlyLocalized: boolean = true;
  @Input() options: any = {};
  @Input() required: boolean;
  @Input() showRequestSmsCodeButton = false;
  @Input() smsPhoneButtonDisabled = false;
  @Input() smsButtonloading = false;
  @Input({ transform: numberAttribute }) tabIndex: number;
  @Output() requestSmsCodeEvent = new EventEmitter<any>();
  @Output() blurEvent = new EventEmitter<any>();

  @ViewChild('intlTelInput') private _inputElement: ElementRef;

  telInputControl = new UntypedFormControl();

  private _intlTelInput: any;
  private onTouch: Function = () => void (0);
  private onModelChange: Function = () => void (0);
  private onValidatorChange: Function = () => void (0);

  ngOnInit(): void {
    this.telInputControl.valueChanges.subscribe(
      (result: any) => {
        if (!result) {
          if (this.onModelChange) {
            this.onModelChange(result);
          }
        }
        if (result && this.onModelChange) {
          this.setPhoneNumber(result);
          if (this.onTouch && !this.telInputControl.touched) {
            this.onTouch();
          }
          if (this.onValidatorChange) {
            this.onValidatorChange();
          }
        }
      }
    );
  }

  onClick(): void {
    if (this.onTouch) {
      this.onTouch();
    }
  }

  onBlur(): void {
    if (this.onTouch) {
      this.onTouch();
    }
    this.blurEvent.emit();
  }

  private static modifyCountryData(): void {
    (window as any).intlTelInputGlobals.getCountryData().forEach((country: any) =>
      country.name = country.name.replace(/.+\((.+)\)/, '$1'));
  }

  public ngAfterViewInit(): void {
    if (this.onlyLocalized) {
      CwIntlTelInputComponent.modifyCountryData();
    }
    this.options = {
      initialCountry: this.defaultCountry,
      separateDialCode: true,
      preferredCountries: WIDGET_CONSTANTS.PREFFERED_COUNTRIES,
      formatOnDisplay: true,
      autoHideDialCode: true,
      utilsScript: WIDGET_CONSTANTS.UTIL_SCRIPTS
    };

    const intlTelInputInstance = intlTelInput;
    this._intlTelInput = intlTelInputInstance(this._inputElement.nativeElement, this.options);
  }

  setPhoneNumber(value: string) {
    if (this._intlTelInput) {
      if (!!value) {
        this._intlTelInput.setNumber(value);
      }
      // this._phoneNumber = value;
      this.i18nizePhoneNumber();
    }
  }

  public i18nizePhoneNumber(): void {
    this.E164PhoneNumber = this._intlTelInput.getNumber();
    if (this.E164PhoneNumber) {
      this.onModelChange(this.E164PhoneNumber);
    } else {
      if (this._intlTelInput.selectedDialCode && this._intlTelInput.selectedDialCode.innerText) {
        if (this.telInputControl.value && this.telInputControl.value[0] === '+') {
          this.E164PhoneNumber = this._intlTelInput.selectedDialCode.innerText + this._intlTelInput.telInput.value;
        } else {
          this.E164PhoneNumber = this._intlTelInput.selectedDialCode.innerText + this.telInputControl.value;
        }
        this.onModelChange(this.E164PhoneNumber);
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
    this.telInputControl.setValue(obj);
  }

  validate(control: AbstractControl): ValidationErrors {
    if (control.value && this._intlTelInput) {
      const selectedCountryCode = this._intlTelInput.getSelectedCountryData();
      if (control.value === `+${selectedCountryCode.dialCode}`) {
        if (this.required) {
          control.setErrors({
            required: true
          });
          return control.errors;
        }
      } else if (!this._intlTelInput.isValidNumber() && this._intlTelInput.isValidNumber() !== null) {
        control.setErrors({
          invalidNumber: true
        });
        return control.errors;
      }
    }
    return null;
  }

  registerOnValidatorChange(fn: any): void {
    this.onValidatorChange = fn;
  }

  onReqestSmsCode(): void {
    if (this.validate(this.telInputControl) === null) {
      this.requestSmsCodeEvent.emit();
    }
  }
}
